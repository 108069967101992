<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="searchData">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Designation</label>
                          <v-select
                            v-model="designation"
                            label="name"
                            placeholder="None"
                            :options="designationOptions"
                            @input="getEmployee(designation)"
                          />
                          <!-- {{ designationOptions }} -->
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Employee</label>
                          <v-select
                            v-model="employee"
                            label="name"
                            placeholder="None"
                            :options="employeeOptions"
                          >
                            <template
                              #option="{ fullname, profile_image, username, surname }"
                            >
                              <b-avatar
                                v-if="fullname != 'All'"
                                :src="getprofileImage(profile_image)"
                              />
                              <span class="font-weight-bolder"> {{ fullname }}</span
                              ><span v-if="fullname != 'All'">({{ username }})</span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Type</label>
                          <v-select
                            v-model="type"
                            label="name"
                            :searchable="false"
                            :clearable="false"
                            placeholder="None"
                            :options="['Working', 'Resigned', 'Both']"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            style="background-color: transparent"
                            @input="getdata($event)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <report-table :data="data" :emailsms="true" :columns="fields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import ReportTable from "../../../components/ReportTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";
import vSelect from "vue-select";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BAvatar,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    vSelect,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    ReportTable,
    BAvatar,
  },
  data() {
    return {
      branchOptions: [
        { name: "Channel Partner", id: "channelpartner" },
        { name: "Franchise", id: "franchise" },
        { name: "Admin", id: "admin" },
      ],
      branchname: "",
      type: "Both",
      data: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        { field: "id", label: "S.R. No", hidden: true },
        { field: "branchname.name", label: "Branch" },
        // { field: 'brand', label: 'Brand',  },
        { field: "name", label: "Employee Name" },
        { field: "position.department.name", label: "Department" },
        {
          field: "position.name",
          label: "Designation",
          url: "getPosition",
          type: "dropdown",
          responseValue: "name",
        },
        { field: "parent.name", label: "Reporting" },
        { field: "joiningdate", label: "Join Date" },
        { field: "birthdate", label: "Birth Date" },
        { field: "resigndate", label: "Resigned Date" },
        { field: "duration", label: "Duration" },
        { field: "address", label: "Address" },
        { field: "mobile", label: "Mobile" },
        { field: "salary", label: "Salary" },
      ],
      employee: "",
      employeeOptions: [],
      data1: [],
      designationOptions: [],
      designation: "",
    };
  },
  mounted() {
    this.getPosition();
  },
  methods: {
    async getPosition() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getPosition`,
      }).then((response) => {
        this.designationOptions = response.data.data;
        this.designationOptions.unshift({ name: "All", id: 0 });
      });
    },
    async getEmployee(item) {
      this.employee = null;
      this.employeeOptions = [];
      let url = `getPositionWiseEmployee/${item.id}`;
      if (item.name == "All") {
        url = "getEmployee";
      }
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/${url}`,
      }).then((response) => {
        this.employeeOptions = response.data.data;
        this.employeeOptions.unshift({ fullname: "All", id: 0 });
      });
    },
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      const data = {
        type: this.type,
        startdate: this.startdate,
        enddate: this.enddate,
        designation: this.designation.id,
        employee_id: this.employee ? this.employee.id : "",
      };
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == true) {
          await axios({
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
            url: `${this.baseApi}/employeereport`,
          }).then((response) => {
            this.data = response.data.data;
            this.data.map((item) => {
              item.salary = item.salary;
              item.birthdate = moment(item.birthdate).format("DD/MM/YY");
              item.joiningdate = moment(item.joiningdate).format("DD/MM/YY");
              if (moment(item.resigndate, "YYYY-MM-DD", true).isValid()) {
                item.resigndate = moment(item.resigndate).format("DD/MM/YYYY");
              } else {
                item.resigndate = "-";
              }

              let startDate = moment(item.joiningdate, "DD/MM/YY");
              let endDate;

              if (moment(item.resigndate, "DD/MM/YYYY", true).isValid()) {
                // If client resigned, calculate duration from joining date to resigned date
                endDate = moment(item.resigndate, "DD/MM/YYYY");
              } else {
                // If client hasn't resigned, calculate duration from joining date to present date
                endDate = moment();
              }

              let duration = moment.duration(endDate.diff(startDate));
              let years = Math.floor(duration.asYears());
              let months = Math.floor(duration.asMonths()) % 12;

              item.duration = years + " years, " + months + " months";
              // if (item.salary.length !== 0) {
              //   item.salary = item.salary[0].salary;
              // } else {
              //   item.salary = "";
              // }
            });
            this.fields.map(async (item) => {
              if (item.type == "dropdown") {
                const accessToken = localStorage.getItem("accessToken");
                const baseApi = process.env.VUE_APP_APIENDPOINT;
                const requestOptionsUsers = {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                  },
                  url: `${baseApi}/${item.url}`,
                };

                await axios(requestOptionsUsers).then((response) => {
                  response.data.data.data
                    ? // ? (this.data[Array(item.url)] = response.data.data.data)
                      (this.data1[item.url] = response.data.data.data)
                    : response.data.data
                    ? (this.data1[item.url] = response.data.data)
                    : (this.data1[item.url] = response.data);

                  this.data.map((data) => {
                    this.data1[item.url].map((data1) => {
                      if (data[item.field] == data1.id) {
                        data[item.field] = data1[item.responseValue];
                      }
                    });
                  });
                });
              }
            });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
